<template>
    <modalupload v-if="modalalert">
   <div slot="body">
     <div align="center">
     <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--ic" 
     width="100" height="100" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M24 8.98A16.88 16.88 0 0 0 12 4C7.31 4 3.07 5.9 0 8.98L12 21v-9h8.99L24 8.98zM19.59 14l-2.09 2.09L15.41 14L14 15.41l2.09 2.09L14 19.59L15.41 21l2.09-2.08L19.59 21L21 19.59l-2.08-2.09L21 15.41L19.59 14z" fill="currentColor"></path></svg>
     <div class="font_22">
     Нет подключения к Интернету
     </div>
     <br/>
     <br/>
     <button class="btns" @click="fclick()">ЗАКРИТЬ</button>
     </div>
   </div>
   </modalupload>  
</template>
<script>
import modalupload from '../blocks/modalupload';
export default {
   components:{
    modalupload
  },
  props: {   
    propsmodalalert: Boolean,
   }, 
  data(){
      return{
          modalalert:false
      }
  },
  watch: {
    propsmodalalert: {
      handler() {
        this.modalalert=this.propsmodalalert;
      }
    },
  },
  methods:{
    fclick(){
      //this.$emit('emitnoconnection', this.modalalert);
      this.modalalert=false;
    }
  }
  }

</script>
