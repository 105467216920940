import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../page/Home.vue'
import moderator from '../page/moderator.vue'
import kod from '../page/kod.vue'
import message from '../page/message.vue'
import chat from '../page/chat.vue'
import dobv from '../page/dobv.vue'
import images from '../page/images.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/moderator',
    name: 'moderator',
    component: moderator
  },
  {
    path: '/kod',
    name: 'kod',
    component: kod
  },
  {
    path: '/message',
    name: 'message',
    component: message
  },
  {
    path: '/chat',
    name: 'chat',
    component: chat
  },
  {
    path: '/dobv',
    name: 'dobv',
    component: dobv
  },
  {
    path: '/images',
    name: 'images',
    component: images
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
