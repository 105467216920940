<template>
 <div class="margin5">
   <div >
       
   </div>
  <div>
  <div><h2># {{id_obv}}</h2></div>
  <kpk :propskategsuccess="propskategsuccess"
       :propspodkategsuccess="propspodkategsuccess"
        :kate="propskate"
        :podkate="propspodkate"
       @emitpodkateg="emitpodkateg" @emitkateg="emitkateg"/>
  <div>
     <div class="div_placeholder">{{text_placeholder_title}}</div>
     <input type="text" class="inputt" v-model.trim="title_obv" @keyup="f_title" placeholder="Заголовок">
     <div class="success">{{text_success_title}}</div>
  </div> 
  <div v-if="blockformtextarea">
     <div class="div_placeholder">{{text_placeholder_body}}</div>
    
      <resizable-textarea ref="resize">
      <textarea ref="textarea" rows="1" class="textareat" v-model.trim="body" @keyup="f_body" placeholder="Описание"></textarea>
    </resizable-textarea>
     <div class="success">{{text_success_body}}</div>
  </div>  
  <div>
     <div class="div_placeholder">{{text_placeholder_sena}}</div>
     <div class="content_sena">
       <table><tr>
         <td class= "td_sena">
            <input type="number" class="inputt" v-model.trim="sena" @keyup="f_sena" placeholder="Цена">
         </td>
         <td>
           <div class = "text_somoni" >сомони</div>
         </td>
         </tr></table>
     </div>
     <div class="success">{{text_success_sena}}</div>
     <div class="content_torg_obmen">
      <div class="checkbox">
      <input class="custom-checkbox" v-model="torg"  type="checkbox" id="torg" name="torg" value="indigo">
      <label for="torg">торг</label>
      </div>
      <div class="checkbox">
      <input class="custom-checkbox"  v-model="obmen" type="checkbox" id="obmen" name="obmen" value="indig55o">
      <label for="obmen">обмен</label>
      </div>
     </div>
  </div>  
  <br/> 
   <gorod :goro="gorod" :propsgorodsuccess="propsgorodsuccess" @emittextgorod="emittextgorod" @emitgorod="emitgorod" />
   <div>
     <div class="div_placeholder">Тел</div>
     <div class="div_tel" >{{tel}}</div>
  </div>
  <div>
    <br/>
     <div class="div_placeholder">{{text_placeholder_name}}</div>
     <input type="text" class="inputt" v-model="name_user_obv" @keyup="f_name" placeholder="Имя">
     <div class="success">{{text_success_name}}</div>
  </div> 
  <div class="content_chat">
  <div class="checkbox">
      <input class="custom-checkbox" v-model="chat"  type="checkbox" id="chat" name="chat" value="indigo">
      <label for="chat">Отключить чат</label>
  </div>
  </div>
  <br/>
  <div class="content_upload_img">
    <div class="div_upload_image">
        <div class = "img_pp">
           <img :src =  image_p width="100%">
        </div>
      <div v-if="img_upload_1">
        <img :src =  img1   class="img_load"  >
          <button class="btnd" @click="delete_img(img_upload_1)">Удалить</button>
          <button class="btnp" @click="rotate_img(img_upload_1)">Повернуть</button>
           <button class="btng" @click="glavnay_img(img_upload_1)">Главная</button>
      </div>
      <div v-if="img_upload_2">
        <img :src =  img2   class="img_load"  >
          <button class="btnd" @click="delete_img(img_upload_2)">Удалить</button>
          <button class="btnp" @click="rotate_img(img_upload_2)">Повернуть</button>
           <button class="btng" @click="glavnay_img(img_upload_2)">Главная</button>
      </div>
      <div v-if="img_upload_3">
        <img :src =  img3   class="img_load"  >
          <button class="btnd" @click="delete_img(img_upload_3)">Удалить</button>
          <button class="btnp" @click="rotate_img(img_upload_3)">Повернуть</button>
           <button class="btng" @click="glavnay_img(img_upload_3)">Главная</button>
      </div>
      <div v-if="img_upload_4">
        <img :src =  img4   class="img_load"  >
          <button class="btnd" @click="delete_img(img_upload_4)">Удалить</button>
          <button class="btnp" @click="rotate_img(img_upload_4)">Повернуть</button>
           <button class="btng" @click="glavnay_img(img_upload_4)">Главная</button>
      </div>
      <div v-if="img_upload_5">
        <img :src =  img5   class="img_load"  >
          <button class="btnd" @click="delete_img(img_upload_5)">Удалить</button>
          <button class="btnp" @click="rotate_img(img_upload_5)">Повернуть</button>
           <button class="btng" @click="glavnay_img(img_upload_5)">Главная</button>
      </div>
      <div v-if="img_upload_6">
        <img :src =  img6   class="img_load"  >
          <button class="btnd" @click="delete_img(img_upload_6)">Удалить</button>
          <button class="btnp" @click="rotate_img(img_upload_6)">Повернуть</button>
           <button class="btng" @click="glavnay_img(img_upload_6)">Главная</button>
      </div>
   </div>
   
  </div>
   <br/>
   <br/>
   <div v-if="content_textnotrue">
   <resizable-textarea ref="resize">
      <textarea ref="textarea" rows="1" class="textareat" v-model.trim="textnotrue"  placeholder="Причина забилакировано"></textarea>
    </resizable-textarea>
   </div>
   <br/> 
   <hr/>
   <br/> 
   <div align="center">
   <button class="btnp" @click="Notrue">ЗАБЛОКИРОВАТЬ</button>
   <button class="btns" @click="Opublikovat">ОПУБЛИКОВАТЬ</button>
    <br/><br/><span @click="popitka=!popitka">del</span> <br/><br/>
    <div v-if="popitka>0"><button class="btnd" @click="deleteobv">Удалить({{popitka}})</button></div>
   </div>
   <br/><br/><br/>
   <modalupload v-if="startupload" >
   <div slot="body">
     <table><tr><td><Spinner /></td><td ><span class="font_22">Идёт загрузка...</span></td></tr></table>
      
   </div>
   </modalupload>
   <noconnection :propsmodalalert="propsmodalalert" @emitnoconnection="emitnoconnection" />
  </div>
  </div> 
</template>

<script>
import ResizableTextarea from '../blocks/ResizableTextarea'
import Spinner from '../blocks/spinner';
import modalupload from '../blocks/modalupload';
import noconnection from '../blocks/noconnection';
import kpk from '../blocks/kpk';
import gorod from '../blocks/gorod';
import axios from "axios";
export default {
 
  components:{
    Spinner,  kpk, gorod, modalupload, noconnection, ResizableTextarea
  },
  data() {
   
     return {
      hasImage: false,
      image: null,
      posts:[
           
      ],
      id_obv:0,
      popitka:0,
      idicator_upload_image:"",
      img_btn_upload: true,
      img_btn:true,
      name: 'WebsymbolHeartEmpty',
      title: "Подать объявления",
      kateg:"",
      podkateg:"",
      propskate:"",
      propspodkate:"",
      propskategsuccess:"",
      blockformtextarea:false,
      propspodkategsuccess:"",
      title_obv:"",
      body:"",
      sena:"",
      torg:false,
      obmen:false,
      name_user_obv:"",
      propsmodalalert:false,
      tel:"",
      chat:false,
      gorod:"",
      gorodtext:"",
      propsgorodsuccess:"",
      text_placeholder_title:"",
      text_success_title:"",
      text_placeholder_body:"",
      text_success_body:"",
      text_success_img:"",
      text_placeholder_sena:"",
      text_success_sena:"",
      text_placeholder_name:"Имя", 
      text_success_name:"",
      showpodkateg:false,
      status_success:true,
      startupload:false,
      img_upload_1:"",
      img_upload_2:"",
      img_upload_3:"",
      img_upload_4:"",
      img_upload_5:"",
      img_upload_6:"",
      content_textnotrue:false,
      textnotrue:"",
      img1:"",
      img2:"",
      img3:"",
      img4:"",
      img5:"",
      img6:"",
      image_p:"",
      imag_p_del:"",
      imgdel:"",
      i:0,
      ochirad:false,
      popitkadelimg:1,
    };
  },
  methods: {
  

  
   emitkateg (kategid) {
     this.kateg=kategid;
     console.log(this.kateg);
   },
   emitpodkateg (podkategid) {
     this.podkateg=podkategid;
      console.log(this.podkateg);
   },
   emitgorod (gorodid) {
     this.gorod=gorodid;
      console.log(this.gorod);
   },
   emitnoconnection(){
    if(this.ochirad){
      this.propsmodalalert=false;
       this.Opublikovat();
    }
    else{
       this.$router.go();
    }
   },
    emittextgorod (gorodidtext) {
     this.gorodtext=gorodidtext;
      console.log(this.gorodtext);
   },
   f_title(){
     if (this.title_obv.length > 0) {
       this.text_placeholder_title="Заголовок";
       this.text_success_title="";
     }
     else this.text_placeholder_title="";
   },
   f_body(){
     if (this.body.length > 0){
      this.text_placeholder_body="Описание";
      this.text_success_body="";
     } 
     else this.text_placeholder_body="";
   },
   f_sena(){
     if (this.sena > 0) this.text_placeholder_sena="Цена";
     else this.text_placeholder_sena="";
   },
   f_name(){
     if (this.name_user_obv.length > 0){
        this.text_placeholder_name="Имя";
        this.text_success_name="";
     } 
     else this.text_placeholder_name="";
   },
   check(){
     this.status_success=true;
     if(this.kateg==""){
       this.propskategsuccess="Выберите категорию";
       this.status_success=false;
     }else{
       if(this.podkateg==""){
         this.propspodkategsuccess="Выберите подкатегорию";
         this.status_success=false;
       }
     }
     if(this.title_obv<2){
       this.status_success=false;
       this.text_success_title="Заголовок не может быть пустым";
     }
     if(this.title_obv>60){
       this.status_success=false;
       this.text_success_title="Максималньная длина 60-символ";
     }
     if(this.body>1000){
       this.status_success=false;
       this.text_success_body="Максималньная длина 1000-символ";
     }
     if(this.body<3){
       this.status_success=false;
       this.text_success_body="Описание не может быть пустым";
     }
      if(this.gorod==""){
       this.propsgorodsuccess="Выберите свой город из списка";
       this.status_success=false;
     }
     if(this.name_user_obv<2){
       this.status_success=false;
       this.text_success_name="Введите свои имя";
     }
     if(this.name_user_obv>60){
       this.status_success=false;
       this.text_success_name="Максималньная длина 60-символ";
     }
     return this.status_success;
   },
   Opublikovat() {
     if(this.check()){
       if(!this.textnotrue.length)
       this.actirovat();
       else alert("Вы должен заблокировать эту объявлению")
     }
   },
   Notrue() {
     if(this.check()){
       if(!this.content_textnotrue){
         this.content_textnotrue=true;
       }
       else{
         if(!this.textnotrue.length){
           alert("Напишите причина заблокирование объявления")
         }
         else this.zablokirovat();
       }
       
     }
   },
  deleteobv(){
   this.popitka=this.popitka+1;
  if(this.popitka==4){
    this.startupload=true;
   this.delobv();
  }
  },
  /*delete_all_img(){
   this.startupload=true;
    if(this.image_p  && this.img_upload_1) this.imgdel=this.image_p;
    else if (this.img_upload_1) this.imgdel=this.img_upload_1;
    else if (this.img_upload_2) this.imgdel=this.img_upload_2;
    else if (this.img_upload_3) this.imgdel=this.img_upload_3;
    else if (this.img_upload_4) this.imgdel=this.img_upload_4;
    else if (this.img_upload_5) this.imgdel=this.img_upload_5;
    else if (this.img_upload_6) this.imgdel=this.img_upload_6;
    else{this.delobv(); }
    if(this.imgdel){
       axios({
           method: 'post',
            url: 'https://agros.tj/api/delimg',
            data: {
            image_del:this.imgdel, pass:localStorage.getItem("tokenadmin")
           }
          })
        .then(res=>{
            if(res.data.status){
               this.startupload=false;
              if(this.imgdel==this.image_p)
               this.image_p="";
              if(this.imgdel==this.img_upload_1)
               this.img_upload_1="";
              if(this.imgdel==this.img_upload_2)
               this.img_upload_2="";
              if(this.imgdel==this.img_upload_3)
               this.img_upload_3="";
              if(this.imgdel==this.img_upload_4)
               this.img_upload_4="";
              if(this.imgdel==this.img_upload_5)
               this.img_upload_5="";
              if(this.imgdel==this.img_upload_6)
               this.img_upload_6="";
              if(this.img_upload_1 || this.img_upload_2 || this.img_upload_2 || this.img_upload_3 || this.img_upload_4 || this.img_upload_5 || this.img_upload_6){
                this.delete_all_img();
              }
              else{
                this.delobv();
              }

            }
            else{ }
        }).catch(error => {
         alert("error")
      }) }  
  },*/
  delobv(){
     this.startupload=true;
      try{
         axios({
           method: 'post',
            url: 'https://agros.tj/api/delobv',
            data: {
            id:this.id_obv,
            tokenadmin:localStorage.getItem("tokenadmin")
           }
          })
        .then(res=>{
            if(res.data.status){
              this.$router.push({name:"Home"});
            }
            else{
             this.startupload=false;
             alert("Произошла ошибка попробуйте снова");
            }    
        })    
     } 
     catch (e){
         alert ("Error");
     }
  },
  delete_img(img){
    this.popitkadelimg=this.popitkadelimg+1;
    if(this.popitkadelimg==3){
       this.startupload=true;
       axios({
           method: 'post',
            url: 'https://agros.tj/api/delimg',
            data: {
            image_del:img, pass:localStorage.getItem("tokenadmin")
           }
          })
        .then(res=>{
            if(res.data.status){
               this.startupload=false;
              if(img==this.img_upload_1)
               this.img_upload_1="";
              if(img==this.img_upload_2)
               this.img_upload_2="";
              if(img==this.img_upload_3)
               this.img_upload_3="";
              if(img==this.img_upload_4)
               this.img_upload_4="";
              if(img==this.img_upload_5)
               this.img_upload_5="";
              if(img==this.img_upload_6)
               this.img_upload_6="";
            }
            else{ }
            this.popitkadelimg=1;
        }).catch(error => {
         alert("error")
      })  }  
   },

rotate_img(img){
         this.startupload=true;
         axios({
           method: 'post',
            url: 'https://imgagros.ru/api/rotate',
            data: {
            image_rotate:img, pass:localStorage.getItem("tokenadmin")
           }
          })
        .then(res=>{
            if(res.data.status){
               this.startupload=false;
              if(img==this.img_upload_1)
              this.img1=this.img_upload_1+"?"+ new Date().getTime();
              if(img==this.img_upload_2)
              this.img2=this.img_upload_2+"?"+ new Date().getTime();
              if(img==this.img_upload_3)
              this.img3=this.img_upload_3+"?"+ new Date().getTime();
              if(img==this.img_upload_4)
              this.img4=this.img_upload_4+"?"+ new Date().getTime();
              if(img==this.img_upload_5)
              this.img5=this.img_upload_5+"?"+ new Date().getTime();
              if(img==this.img_upload_6)
              this.img6=this.img_upload_6+"?"+ new Date().getTime();
            }
            else{ }
        }).catch(error => {
         alert("error")
      })    
},
glavnay_img(img){
      this.startupload=true;
 try{
         axios({
           method: 'post',
            url: 'https://imgagros.ru/api/crop',
            data: {
            image_p:img,
           }
          })
        .then(res=>{
            if(res.data.status){
              this.imag_p_del=this.image_p;
              this.image_p=res.data.answer;
               axios({
                  method: 'post',
                  url: 'https://agros.tj/api/delimg',
                  data: {
                  image_del:this.imag_p_del, pass:localStorage.getItem("tokenadmin")
                }
                })
              .then(res=>{
                  if(res.data.status){
                    this.startupload=false;
                  }
                }).catch(error => {
                alert("error")
                })
            }
            else{ alert("error");}
        }).catch(error => {
        if (!error.response) {
            alert("error");
        }
      })    
     } 
     catch (e){
         alert ("Error");
     } 
   },





actirovat(){
   this.startupload=true;
   if(this.sena=="") this.sena=0.0001;
      try{
         axios({
           method: 'post',
            url: 'https://agros.tj/api/activno',
            data: {
            id:this.id_obv,
            title:this.title_obv,
            name:this.name_user_obv,
            tel:this.tel,
            body:this.body,
            sena:this.sena,
            torg:this.torg,
            obmen:this.obmen,
            adress_index:this.gorod,
            adress_text:this.gorodtext,
            kateg:this.kateg,
            podkateg:this.podkateg,
            chat:this.chat,
            imgp:this.image_p,
            img1:this.img_upload_1,
            img2:this.img_upload_2,
            img3:this.img_upload_3,
            img4:this.img_upload_4,
            img5:this.img_upload_5,
            img6:this.img_upload_6,
            tokenadmin:localStorage.getItem("tokenadmin")
           }
          })
        .then(res=>{
          if(!res.data.message){
            if(res.data.status){
               this.$router.push({name:"Home"});
            }
            else{
             this.startupload=false;
             alert("Произошла ошибка попробуйте снова");
            }
          }
          else{
              localStorage.removeItem("tokenadnim");
              this.$router.go();
          } 
              
        }).catch(error => {
        if (!error.response) {
            this.startupload=false;
            this.propsmodalalert=true;
        }
      })    
     } 
     catch (e){
         alert ("Error");
     }
  },
  zablokirovat(){
   this.startupload=true;
   if(this.sena=="") this.sena=0.0001;
      try{
         axios({
           method: 'post',
            url: 'https://agros.tj/api/notrue',
            data: {
            id:this.id_obv,
            title:this.title_obv,
            name:this.name_user_obv,
            tel:this.tel,
            body:this.body,
            sena:this.sena,
            torg:this.torg,
            obmen:this.obmen,
            adress_index:this.gorod,
            adress_text:this.gorodtext,
            kateg:this.kateg,
            podkateg:this.podkateg,
            chat:this.chat,
            imgp:this.image_p,
            img1:this.img_upload_1,
            img2:this.img_upload_2,
            img3:this.img_upload_3,
            img4:this.img_upload_4,
            img5:this.img_upload_5,
            img6:this.img_upload_6,
            textnotrue:this.textnotrue,
            tokenadmin:localStorage.getItem("tokenadmin")
           }
          })
        .then(res=>{
          if(!res.data.message){
            if(res.data.status){
               this.$router.push({name:"Home"});
            }
            else{
             this.startupload=false;
             alert("Произошла ошибка попробуйте снова");
            }
          }
          else{
              localStorage.removeItem("tokenadnim");
              this.$router.go();
          } 
              
        }).catch(error => {
        if (!error.response) {
            this.startupload=false;
            this.propsmodalalert=true;
        }
      })    
     } 
     catch (e){
         alert ("Error");
     }
  },
   getobvproveka(){
     if(localStorage.getItem("tokenadmin")){
       this.startupload=true;
      try{
         axios({
           method: 'post',
            url: 'https://agros.tj/api/getobv',
            data: {
            tokenadmin:localStorage.getItem("tokenadmin"),
           }
          })
        .then(res=>{
            if(res.data.status){
              if(res.data.obv=="no"){
               alert("Пока нет обявления для проверка");
               this.startupload=false;      
              }
              else {
               this.id_obv=res.data.obv.id;
               if(res.data.obv.kateg){
               this.propskate=String(res.data.obv.kateg);
               this.propspodkate=String(res.data.obv.podkateg);
               }
               this.title_obv=res.data.obv.title;
               this.f_title();
               this.body=res.data.obv.body;
               this.f_body();
               this.blockformtextarea=true;
               this.sena=res.data.obv.sena;
               this.f_sena();
               if(res.data.obv.torg=="0")this.torg=false;
               else this.torg=true;
               if(res.data.obv.obmen=="0")this.obmen=false;
               else this.obmen=true;
               this.gorod=String(res.data.obv.adress_index);
               this.tel=res.data.obv.tel;
               this.name_user_obv=res.data.obv.name;
               this.f_name;
               if(res.data.obv.chat=="0")this.chat=false;
               else this.chat=true;
               this.image_p=res.data.obv.img_p;
               this.img_upload_1=res.data.obv.img_1;
               this.img1=this.img_upload_1;
               this.img_upload_2=res.data.obv.img_2;
               this.img2=this.img_upload_2;
               this.img_upload_3=res.data.obv.img_3;
               this.img3=this.img_upload_3;
               this.img_upload_4=res.data.obv.img_4;
               this.img4=this.img_upload_4;
               this.img_upload_5=res.data.obv.img_5;
               this.img5=this.img_upload_5;
               this.img_upload_6=res.data.obv.img_6;
               this.img6=this.img_upload_6;
               this.startupload=false;
              } 
            }
            else{
            // this.spin=false;
            }
        }).catch(error => {
        
      })    
     } 
     catch (e){
         alert ("Error");
     } 
     }
   }
  },
  mounted(){
   this.getobvproveka();  

  },
  created(){
   if(!localStorage.getItem("tokenadmin")){
       this.$router.push({name:"Home"});
    }     
},
};
</script>

<style>
  
#fileInput {
display: none;
}
.div_placeholder {
    height: 16px;
    font-size: 14px;
    color:#666666;
    margin-left:3px;
}
.content_chat{
  margin-left:-15px;
  margin-top:10px;
}
.margin5{
  margin: 5px;
}
.img_pp{
  width:150px;
}
.div_tel {
  width:98%;
  height:22px;
  border: 1px solid #ffffff;
  border-bottom-color: #000000;
  padding:5px 0px 5px 3px;
  font-size: 20px;
}
.inputt {
  width:98%;
  border: 1px solid #ffffff;
  border-bottom-color: #000000;
  padding:5px 0px 5px 3px;
  font-size: 20px;
}
.textareat {
 width:98%;
  border: 1px solid #ffffff;
  border-bottom-color: #000000;
  padding:3px 0px 3px 3px;
  font-size: 20px;
  resize: none;
}
.success {
  height: 15px;
  font-size: 15px;
  color:#c20404;
  margin-left:3px;
}
.div_upload_image div {

border-radius: 10px;
margin: 5px 5px;

}
.div_upload_image{
   max-width: 600px;
  min-width: 250px;
}
.content_torg_obmen{
display: inline-flex;
margin-left:-11px;
}
.content_sena table{
  width:100%;
}
.text_somoni {
  font-size:20px;
  color:#777777;
}
.td_sena{
  width:100%;
}
.img_load {
width:100%;
border-radius: 5px;
}

</style>
