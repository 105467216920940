<template>
 <div class="margin5">
   <div class="cotinerdobv">
   </div>
    <div class="contentdelobv" v-for="img in imgs" :key="img.id">
       <table border="0" width="100%">
        <tr>
            <td><div class="img"><img :src="img.image"> </div></td>
            <td colspan="2" align="right" @click="delimg(img.id,img.image)"><button class="btnd">Удалить</button></td>
        </tr>
       </table>
     <hr/>
    </div>
   <modalupload v-if="startupload" >
   <div slot="body">
     <table><tr><td><Spinner /></td><td ><span class="font_22">Идёт загрузка...</span></td></tr></table>
      
   </div>
   </modalupload>
  </div> 
</template>

<script>

import Spinner from '../blocks/spinner';
import modalupload from '../blocks/modalupload';
import axios from "axios";
export default {
 
  components:{
    Spinner, modalupload  
  },
  data() {
     return {
     startupload:false,
     imgs:[],
     };
  },
  methods: {
  getdelimg(){
  if(localStorage.getItem("tokenadmin")){
   this.startupload=true;
      try{
         axios({
           method: 'post',
            url: 'https://agros.tj/api/getdelimg',
            data: {
            tokenadmin:localStorage.getItem("tokenadmin")
           }
          })
        .then(res=>{
            if(res.data.status){
              this.startupload=false;
              if(res.data.imgs!="no"){
                 this.imgs=res.data.imgs; 
              }
              else{
                alert("Нет картинка для удаления");
              }
            }
            else{
             this.startupload=false;
             alert("Произошла ошибка попробуйте снова");
            }    
        })    
     } 
     catch (e){
         alert ("Error");
     }
  }
  },
  delimg(id,image){
     this.startupload=true;
      try{
         axios({
           method: 'post',
            url: 'https://imgagros.ru/api/delimg',
            data: {
            image_del:image,
            pass:localStorage.getItem("tokenadmin")
           }
          })
        .then(res=>{
            if(res.data.status){
                 try{
                        axios({
                        method: 'post',
                            url: 'https://agros.tj/api/delimgtable',
                            data: {
                            id:id,
                            tokenadmin:localStorage.getItem("tokenadmin")
                        }
                        })
                        .then(res=>{
                            if(res.data.status){ 
                                this.imgs=this.imgs.filter(p=>p.id!==id);
                                this.startupload=false;
                            }
                            else{
                            this.startupload=false;
                            alert("Произошла ошибка попробуйте снова");
                            }    
                        })    
                    } 
                    catch (e){
                        alert ("Error");
                    }
            }
            else{
             this.startupload=false;
             alert("Произошла ошибка попробуйте снова");
            }    
        })    
     } 
     catch (e){
         alert ("Error");
     }
  },

  },
  mounted(){
   this.getdelimg();

  },
  created(){
   if(!localStorage.getItem("tokenadmin")){
       this.$router.push({name:"Home"});
    }     
},
};
</script>

<style  scoped>
.img{
   width: 150px;
   height: 143px;
   background-color: #f5f5f5; 
}
.img img {
  width:auto;
  height: 100%;
}
</style>
