<template >
<div>
    <div class="content_dialog" v-for="dial in dialog" :key="dial.id" @click="f_message( dial.tel_user1)">
         <table>
              <tr>
                   <td>
                      <img src="https://imgagros.ru/storage/img/111111111/11111/logoagros.jpg">
                   </td>
                   <td width="100%">
                    <table>
                         <tr>
                              <td width="100%" valign="top"><div class="title over"><span>Тех. поддержка</span></div></td>
                              <td><label class="dt">{{dial.last_dt}}</label></td>
                         </tr>
                         <tr>
                              <td width="100%"><div class="name over"><span>{{dial.name}}</span></div></td>
                              <td align="right"><div v-if="dial.sender && dial.unread > 0" class="unread">{{dial.unread}}</div></td>
                         </tr>
                         <tr>
                              <td width="100%" colspan="2"><div class="message over"><span>{{dial.last_message}}</span></div></td>
                         </tr>
                    </table>
                   </td>
              </tr>
         </table>
    </div>
</div>
</template>
<script>
export default {
     props: {
      dialog: Array,
     },
     methods:{
          f_message( teluser2){
               localStorage.setItem("tel2",teluser2);
               this.$router.push({name:"chat"}); 
          }
     }
}
</script>
<style scoped>
.content_dialog{
background-color: #fff;
border-radius: 10px;
margin:10px 5px 10px 5px;
}
.content_dialog img{
width:110px;
border-radius: 10px;
margin-bottom:-4px;
margin-right: 3px;
}
.content_dialog table{
border-collapse: collapse;
}
.title{
font-size:18px;
Color:#000;  
}
.name{
font-size:18px;
Color:#000;     
}
.message{
font-size:18px;
Color:#777; 
}
.unread{
padding:1px 4px;
background-color: #ff6600;
display: inline-block;
color:#fff;
font-size:14px;
border-radius: 10px;

}
.dt{
font-size:14px;
Color:#777; 
}
.over{
 word-wrap: break-word;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
-ms-word-break: break-all;
 word-break: break-all;     
}
</style>