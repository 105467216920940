<template>
 <div class="margin5">
   <div class="cotinerdobv">
    <table width="100%">
        <tr>
            <td><button class="btns" @click="getdelobv">На удаления</button></td>
             <td><button class="btns" @click="getstariyobv">Старий Обв</button></td>
        </tr>
    </table>
   </div>
    <div class="contentdelobv" v-for="obv in obvs" :key="obv.id">
       <table border="0" width="100%">
        <tr>
            <td><div class="img"><img :src="obv.img_p"> </div></td>
            <td width="100%">
              <span><b>{{obv.title}}</b></span><br/>
              <label>Прасмотр: {{obv.prasmotr}}</label><br/>
              <label>Килик тел: {{obv.click_tel}}</label><br/>
              <i>{{obv.status}}</i><br/>
              <label> {{obv.dt}}</label>
            </td>
        </tr>
        <tr>
            <td><span><b>{{obv.id}}</b></span></td>
            <td align="right" @click="delobv(obv.id)"><button class="btnd">Удалить</button></td>
        </tr>
       </table>
     <hr/>
    </div>
   <modalupload v-if="startupload" >
   <div slot="body">
     <table><tr><td><Spinner /></td><td ><span class="font_22">Идёт загрузка...</span></td></tr></table>
      
   </div>
   </modalupload>
  </div> 
</template>

<script>

import Spinner from '../blocks/spinner';
import modalupload from '../blocks/modalupload';
import axios from "axios";
export default {
 
  components:{
    Spinner, modalupload  
  },
  data() {
     return {
     startupload:false,
     obvs:[],
     };
  },
  methods: {
  getdelobv(){
   this.startupload=true;
      try{
         axios({
           method: 'post',
            url: 'https://agros.tj/api/getdelobv',
            data: {
            tokenadmin:localStorage.getItem("tokenadmin")
           }
          })
        .then(res=>{
            if(res.data.status){
              this.startupload=false;
              if(res.data.obvs!="no"){
                 this.obvs=res.data.obvs;
                 
              }
              else{
               
                alert("Нет объявления для удаления");
              }
            }
            else{
             this.startupload=false;
             alert("Произошла ошибка попробуйте снова");
            }    
        })    
     } 
     catch (e){
         alert ("Error");
     }
  },
  getstariyobv(){
   this.startupload=true;
      try{
         axios({
           method: 'post',
            url: 'https://agros.tj/api/getstariyobv',
            data: {
            tokenadmin:localStorage.getItem("tokenadmin")
           }
          })
        .then(res=>{
            if(res.data.status){
              this.startupload=false;
              if(res.data.obvs!="no"){
                 this.obvs=res.data.obvs;
                 
              }
              else{
               
                alert("Нет объявления для удаления");
              }
            }
            else{
             this.startupload=false;
             alert("Произошла ошибка попробуйте снова");
            }    
        })    
     } 
     catch (e){
         alert ("Error");
     }
  },
  delobv(idobv){
     this.startupload=true;
      try{
         axios({
           method: 'post',
            url: 'https://agros.tj/api/delobv',
            data: {
            id:idobv,
            tokenadmin:localStorage.getItem("tokenadmin")
           }
          })
        .then(res=>{
            if(res.data.status){
                this.obvs=this.obvs.filter(p=>p.id!==idobv);
                this.startupload=false;
            }
            else{
             this.startupload=false;
             alert("Произошла ошибка попробуйте снова");
            }    
        })    
     } 
     catch (e){
         alert ("Error");
     }
  },

  },
  mounted(){


  },
  created(){
   if(!localStorage.getItem("tokenadmin")){
       this.$router.push({name:"Home"});
    }     
},
};
</script>

<style  scoped>
.img{
   width: 150px;
   height: 143px;
   background-color: #f5f5f5; 
}
.img img {
  width:100%;
}
.contentdelobv span{
    font-size: 20px;
}
.contentdelobv label{
    font-size: 18px;
}
.contentdelobv i{
    font-size: 18px;
    color:#ff6600;
}
</style>
