<template>
  <div>
    <div>
      <table>
        <tr>
          <td width="100%">
            <div class="div_placeholder">{{text_placeholder_kod}}</div>
            <input type="number" class="inputt" v-model.trim="kod" @keyup.enter="f_kod" placeholder="Код">
            <div class="success">{{text_success_kod}}</div>
          </td>
          <td>
            <button class="btns" @click="f_getobv">Enter</button>
          </td>
        </tr>
      </table>
  </div> 
  <div align="center" v-if="loading">
    <Spinner />
    </div> 
    <div v-if="content" class="content_obv">
      <div>Котег/подкатег: <span> {{post.kateg}}/{{post.podkateg}}</span></div>
      <div>Загаловок: <span>{{post.title}}</span></div>
      <div>Описание: <span>{{post.body}}</span></div>
      <div>Имя: <span>{{post.name}}</span></div>
      <div>Цена: <span>{{post.sena}}</span></div>
      <div>Торг: <span>{{post.torg}}</span></div>
      <div>Обмен: <span>{{post.obmen}}</span></div>
      <div>Время: <span>{{post.dt}}</span></div>
      <div>Чать: <span>{{post.chat}}</span></div>
      <div>Статус: <span>{{post.status}}</span></div>
      <div>Клик тел: <span>{{post.click_tel}}</span></div>
      <div>Прасмотр:<span> {{post.prasmotr}}</span></div>
      <div>Тел:<span> {{post.tel}}</span></div>
      <div>Текст заблокировано: <span>{{post.text_no_true}}</span></div>
      <div>Собития: <span>{{post.sobitiya}}</span></div>
      <div>Картинка</div>
      <div><img :src="post.img_p" ></div>
      <div class="div_img"><img :src="post.img_1" ></div>
      <div class="div_img"><img :src="post.img_2" ></div>
      <div class="div_img"><img :src="post.img_3" ></div>
      <div class="div_img"><img :src="post.img_4" ></div>
      <div class="div_img"><img :src="post.img_5" ></div>
      <div class="div_img"><img :src="post.img_6" ></div>
      <div class="content_btns">
        <button class="btns" @click="f_settingobv(1)">Активно</button>
        <button class="btnp" @click="f_settingobv(2)">Проверка</button>
        <button class="btnd" @click="f_settingobv(5)">Заблокировать</button>
        <button class="btnp" @click="f_settingobv(3)">Скрыть</button>
        <button class="btnd" @click="f_settingobv(4)">Удалить</button>
        <button class="btns" @click="f_settingobv(6)">Обновить дату</button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Spinner from '../blocks/spinner';
export default {

  components: {
   Spinner
  },
  data (){
    return {
     kod:"",
     text_success_kod:"",
     text_placeholder_kod:"",
     loading:false,
     post:[],
     content:false,

    }
  },
  methods:{
   f_kod(){
    if (this.kod > 0) {
       this.text_placeholder_kod="Код";
       this.text_success_kod="";
     }
     else this.text_placeholder_kod="";  
   },
   f_getobv(){
    if(this.kod > 0){
      this.loading=true;
        axios({
           method: 'post',
            url: 'https://agros.tj/api/getitemobv',
            data: {
           tokenadmin:localStorage.getItem("tokenadmin"),
           id:this.kod,
           }
          })
        .then(res=>{
            if(res.data.status){
                this.loading=false;
                this.content=true;
                this.post=res.data.obv;
            }
            else{
             alert("Нет Объявъления");
            }
        }).catch(error => {
        if (!error.response) {
            alert("no conection internet");
        }
      }) 
    }
    else{
     this.text_success_kod="Введите код объявления";  
    }
   },
   f_settingobv(status){
      if(this.kod > 0){
      this.loading=true;
        axios({
           method: 'post',
            url: 'https://agros.tj/api/status',
            data: {
           tokenadmin:localStorage.getItem("tokenadmin"),
           id:this.kod,
           status:status,
           }
          })
        .then(res=>{
            if(res.data.status){
                this.loading=false;
                this.content=false;
                
            }
            else{
             alert("Что то не так ошибка!");
            }
        }).catch(error => {
        if (!error.response) {
            alert("no conection internet");
        }
      }) 
    }
    else{
     this.text_success_kod="Введите код объявления";  
    }
   }
  },
  created(){
   if(!localStorage.getItem("tokenadmin")){
       this.$router.push({name:"Home"});
    }     
},
 
}
</script>
<style scoped>
.content_obv{
  padding:10px;
  color:#333;
  font-size:18px;
  margin-bottom: 10px;
}
.content_obv span{
  color:#000;
}
.div_img{
  height:100%;
width:100%;
overflow-x: auto;
overflow-y: auto;
white-space: nowrap;
}
</style>
