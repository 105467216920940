<template>
<div>
<div class="contentkateg" >
<div class="placeholder_kateg">{{txtplaceholder_kateg}}</div>
<div v-if="!iconkadel" @click="showModalkateg = true" >
<table>
<tr>
<td width="100%">
<div  class='divkateg'>Категория</div>
</td>
<td  align="center">
<div >
<svg width="0.8em" height="0.8em" viewBox="0 0 16 16"><g fill="currentColor"><path fillRule="evenodd" clipRule="evenodd" d="M7.976 10.072l4.357-4.357l.62.618L8.284 11h-.618L3 6.333l.619-.618l4.357 4.357z"></path></g></svg>
</div>
</td>
</tr>
</table>
</div>
<div v-else>
<table>
<tr>
<td width="100%">
<div  class='divkategactiv' @click="showModalkateg = true" >{{txtkateg}}</div>
</td>
<td  align="center">
<div @click="clearkateg()">
  <svg  width="0.8em" height="0.8em" viewBox="0 0 24 24"><path d="M18.36 19.78L12 13.41l-6.36 6.37l-1.42-1.42L10.59 12L4.22 5.64l1.42-1.42L12 10.59l6.36-6.36l1.41 1.41L13.41 12l6.36 6.36z" fill="currentColor"></path></svg>
</div>
</td>
</tr>
</table>
</div>
</div>

<div class="success_kateg">{{txtsuccess_kateg}}</div>

 <modal v-if="showModalkateg" @close="showModalkateg = false;">
<span slot="header">Категория</span>
<div slot="body">
 <div v-for="k in arrkateg" :key="k.id" class="block_radio">
 <input class="custom-radio" name="color" v-model="kateg" type="radio"
 :id="'k'+k.id" :value="k.id" @change="selectkateg">
<label  :for="'k'+k.id">{{k.text}}</label>
 </div>
</div>
</modal>

<div v-if="showModaldivpodkateg">
<div class="contentpodkateg" >
<div class="placeholder_podkateg">{{txtplaceholder_podkateg}}</div>

<div v-if="!iconkadelpodkateg" @click="showModalpodkateg = true" >
<table>
<tr>
<td width="100%">
<div  class='divpodkateg'>Подкатегория</div>
</td>
<td  align="center">
<div >
<svg width="0.8em" height="0.8em" viewBox="0 0 16 16"><g fill="currentColor"><path fillRule="evenodd" clipRule="evenodd" d="M7.976 10.072l4.357-4.357l.62.618L8.284 11h-.618L3 6.333l.619-.618l4.357 4.357z"></path></g></svg>
</div>
</td>
</tr>
</table>
</div>

<div v-else>
<table>
<tr>
<td width="100%">
<div  class='divpodkategactiv' @click="showModalpodkateg = true" >{{txtpodkateg}}</div>
</td>
<td  align="center">
<div @click="clearpodkateg()">
  <svg  width="0.8em" height="0.8em" viewBox="0 0 24 24"><path d="M18.36 19.78L12 13.41l-6.36 6.37l-1.42-1.42L10.59 12L4.22 5.64l1.42-1.42L12 10.59l6.36-6.36l1.41 1.41L13.41 12l6.36 6.36z" fill="currentColor"></path></svg>
</div>
</td>
</tr>
</table>
</div>
</div>

<div class="success_podkateg">{{txtsuccess_podkateg}}</div>

<modal v-if="showModalpodkateg" @close="showModalpodkateg = false;">
<span slot="header">Подкатегория</span>
<div slot="body">
  
  <div v-for="pk in newarrpodkateg"  :key="pk.id" class="block_radio">
  <input class="custom-radio" name="color" v-model="podkateg" type="radio"
  :id="'pk'+pk.id" :value="pk.id" @change="selectpodkateg">
  <label  :for="'pk'+pk.id">{{pk.text}}</label>
 
  </div>
  
</div>
</modal>

</div>
</div>
</template>
<script>
import modal from "../blocks/modal";
export default {
   components:{
      modal,  
   },
   props: {   
    kate: String,
    podkate: String,
    propskategsuccess: String,
    propspodkategsuccess: String,
   }, 
  data() {
    return {
      kateg: this.kate,
      txtkateg:"",
      iconkadel:false,
      txtplaceholder_kateg:"",
      txtsuccess_kateg:"",
      showModalkateg: false,
      arrkateg: [
        {id:"1", text:"Фрукты"},
        {id:"2", text:"Овощи"},
        {id:"3", text:"Зерно"},
        {id:"4", text:"Сад и растения"},
        {id:"5", text:"Животноводство"},
        {id:"6", text:"Продукты животноводства"},
        {id:"7", text:"Работа и услуги"},
        {id:"8", text:"Техника и оборудование"},
        {id:"9", text:"Удобрения и агрохимия"},
      ],
      tkateg:"" , 
      podkateg: this.podkate,
      txtpodkateg:"",
      iconkadelpodkateg:false,
      txtplaceholder_podkateg:"",
      txtsuccess_podkateg:"",
      showModalpodkateg: false,
      arrpodkateg:[
        {id:"11", text:"Абрикос", k:"1"},
        {id:"12", text:"Яблоко", k:"1"},
        {id:"13", text:"Орехи", k:"1"},
        {id:"14", text:"Виноград", k:"1"},
        {id:"15", text:"Банан,  лимон, апельсин", k:"1"},
        {id:"16", text:"Груши, хурма", k:"1"},
        {id:"17", text:"Шиповник", k:"1"},
        {id:"18", text:"Сезонные фрукты", k:"1"},
        {id:"19", text:"Другие фрукты", k:"1"},
        {id:"21", text:"Картофель", k:"2"},
        {id:"22", text:"Лук", k:"2"},
        {id:"23", text:"Морковь", k:"2"},
        {id:"24", text:"Помидоры и огурцы", k:"2"},
        {id:"25", text:"Капуста и зелень", k:"2"},
        {id:"26", text:"Дыня и арбуз", k:"2"},
        {id:"27", text:"Другие овощи", k:"2"},
        {id:"31", text:"Пшеница", k:"3"},
        {id:"32", text:"Рис", k:"3"},
        {id:"33", text:"Фасоль", k:"3"},
        {id:"34", text:"Горох и нут", k:"3"},
        {id:"35", text:"Арахис", k:"3"},
        {id:"36", text:"Ячмень", k:"3"},
        {id:"37", text:"Кукуруза", k:"3"},
        {id:"38", text:"Другие", k:"3"},
        {id:"41", text:"Продажа земельных участков с постройкой", k:"4"},
        {id:"42", text:"Земля в аренду", k:"4"},
        {id:"43", text:"Цветы", k:"4"},
        {id:"44", text:"Растение", k:"4"},
        {id:"45", text:"Дрова", k:"4"},
        {id:"46", text:"Лекарственные  растения", k:"4"},
        {id:"47", text:"Семена", k:"4"},
        {id:"51", text:"Корова", k:"5"},
        {id:"52", text:"Овцы", k:"5"},
        {id:"53", text:"Козы", k:"5"},
        {id:"54", text:"Птицы", k:"5"},
        {id:"55", text:"Рыба", k:"5"},
        {id:"56", text:"Другие", k:"5"},
        {id:"57", text:"Корм для животных", k:"5"},
        {id:"58", text:"Товары для животных", k:"5"},
        {id:"61", text:"Яйцо", k:"6"},
        {id:"62", text:"Молоко", k:"6"},
        {id:"63", text:"Мясо", k:"6"},
        {id:"64", text:"Мёд", k:"6"},
        {id:"65", text:"Другие", k:"6"},
        {id:"71", text:"Работа", k:"7"},
        {id:"72", text:"Услуга", k:"7"},
        {id:"81", text:"Сельхозтехника", k:"8"},
        {id:"82", text:"Мини техника", k:"8"},
        {id:"83", text:"Оборудование", k:"8"},
        {id:"84", text:"Ручной инструмент", k:"8"},
        {id:"85", text:"Запчасти", k:"8"},
        {id:"86", text:"Другие", k:"8"},
        {id:"91", text:"Удобрения", k:"9"},
        {id:"92", text:"Средства защиты растений", k:"9"} 
      ],
      tpodkateg:"",
      newarrpodkateg:[],
      showModaldivpodkateg:false
    };
  },
  methods: {
   selectkateg() {
     if(this.kateg && this.tkateg != this.kateg){
       const values = Object.values(this.arrkateg);
      for (let i = 0; i < values.length; i += 1) {
         if (values[i].id === this.kateg) this.txtkateg=values[i].text;
      }
      this.$emit('emitkateg', this.kateg);
      this.showModalkateg=false;
      this.txtplaceholder_kateg="Категория";
      this.iconkadel=true;
      this.txtsuccess_kateg="";
      this.tkateg=this.kateg;
      this.showModaldivpodkateg=true;
      this.sort();
      this.clearpodkateg();
     } 
  },
  clearkateg(){
      this.kateg="";
      this.$emit('emitkateg', this.kateg);
      this.txtplaceholder_kateg="";
      this.iconkadel=false;
      this.txtkateg="";
      this.tkateg="";
      this.showModaldivpodkateg=false
  },
  sort(){
     this.newarrpodkateg=this.arrpodkateg.filter(p=>p.k==this.kateg);
  },
  selectpodkateg() {
     if(this.podkateg && this.tpodkateg != this.podkateg){
       const values = Object.values(this.arrpodkateg);
      for (let i = 0; i < values.length; i += 1) {
         if (values[i].id === this.podkateg) this.txtpodkateg=values[i].text;
      }
      this.$emit('emitpodkateg', this.podkateg);
      this.showModalpodkateg=false;
      this.txtplaceholder_podkateg="Подкатегория";
      this.iconkadelpodkateg=true;
      this.txtsuccess_podkateg="";
      this.tpodkateg=this.podkateg;
      this.ppp="";
     } 
    },
  clearpodkateg(){
      this.podkateg="";
      this.$emit('emitpodkateg', this.podkateg);
      this.txtplaceholder_podkateg="";
      this.iconkadelpodkateg=false;
      this.txtpodkateg="";
      this.tpodkateg="";
      
   },

  selectkategprops(){
    if(this.kateg && this.tkateg != this.kateg){
       const values = Object.values(this.arrkateg);
      for (let i = 0; i < values.length; i += 1) {
         if (values[i].id === this.kateg) this.txtkateg=values[i].text;
      }
      this.$emit('emitkateg', this.kateg);
      this.showModalkateg=false;
      this.txtplaceholder_kateg="Категория";
      this.iconkadel=true;
      this.txtsuccess_kateg="";
      this.tkateg=this.kateg;
      this.showModaldivpodkateg=true;
      this.sort();
   }
  }
  },
  watch: {
    kate: {
      handler() {
        this.kateg=this.kate;
         this.selectkategprops();
      }
    },
    podkate: {
      handler() {
        this.podkateg=this.podkate;
        this.selectpodkateg();
      }
    },
    propskategsuccess:{
      handler() {
        this.txtsuccess_kateg=this.propskategsuccess;
      }
    },
    propspodkategsuccess:{
      handler() {
        this.txtsuccess_podkateg=this.propspodkategsuccess;
      }
    },
 }  
  };
</script>
<style scoped>
.placeholder_kateg {
    height: 16px;
    font-size: 14px;
    color:#666666;
    margin-left:3px;
}
.block_radio{
  margin-bottom: 1.4em;
}
.divkategactiv{
  width:100%;
  color:#000000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.divkateg{
  width:100%;
  color:#777777;
}
.success_kateg{
    height: 15px;
    font-size: 15px;
    color:#c20404;
    margin-left:3px;
}
.contentkateg{
    font-size: 20px;
    border-bottom: 1px solid #000000;
    padding:5px 5px 5px 0px;
}
 label {
     font-size: 20px;
     width:100%;
 }
 .placeholder_podkateg {
    height: 16px;
    font-size: 14px;
    color:#666666;
    margin-left:3px;
}

.divpodkategactiv{
  width:100%;
  color:#000000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.divpodkateg{
  width:100%;
  color:#777777;
}
.success_podkateg{
    height: 15px;
    font-size: 15px;
    color:#c20404;
    margin-left:3px;
}
.contentpodkateg{
    font-size: 20px;
    border-bottom: 1px solid #000000;
    padding:5px 5px 5px 0px;
}
</style>